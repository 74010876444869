.pagination ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.pagination ul li {
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.pagination ul li a {
  font-size: 14px;
  line-height: 14px;
}

.pagination ul li.active {
  border: 1px solid #1677ff;
  border-radius: 5px;
  color: #1677ff;
}

svg.arrow-right {
  margin-top: 8px;
  margin-left: 8px;
}

svg.arrow-left {
  margin-top: 8px;
  margin-right: 8px;
}
