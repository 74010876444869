body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container-fluid {
  width: 100%;
  max-width: 100%;
}

.container {
  width: 1200px;
  max-width: calc(100% - 40px);
  padding: 20px;
  margin: 0 auto;
}

.header, .footer {
  background: green;
  color: #fff;
}

a {
  text-decoration: none;
  outline: none;
}

.text-large {
  font-size: 150%;
}

.text-center {
  text-align: center;
}

.minHeight100vh {
  min-height: 100vh;
}

.bg-main {
  background: rgb(243, 243, 243);
}

.flex-h {
  display: flex;
  max-width: 100%;
  flex-direction: row;
}

.flex-v {
  display: flex;
  max-width: 100%;
  flex-direction: column;
}

.flex-center {
  justify-content: center;
}

.flex-y-center {
  align-items: center;
}

.grid {
  display: grid;
  gap: 10px;
}

.grid-12 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.grid-11 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.grid-10 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.grid-9 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.grid-8 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.grid-7 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.grid-6 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.grid-5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.grid-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-2 {
  grid-template-columns: 1fr 1fr;
}

.grid-1 {
  grid-template-columns: 1fr;
}

.w200 {
  width: 200px;
  max-width: 100%;
}

.mr-20 {
  margin-left: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.fontBold {
  font-weight: bold;
}

p {
  margin-top: 0;
}

.a-block {
  width: 100%;
  display: inline-block;
  height: 100%;
}

/** customize **/
h1 {
  font-size: 36px;
}

.section-title {
  color: #f00;
  text-transform: uppercase;
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.text-primary {
  color: #fe6429;
  font-size: 16px;
}

.text-secondary {
  color: #f00;
}

.category-title {
  font-size: 16px;
  font-weight: 500;
  color: #fe6429;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.game-title {
  font-size: 20px;
  font-weight: 500;
  color: #fe6429;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.game-title-2 {
  font-size: 20px;
  font-weight: 500;
  color: #fe6429;
}

.game-played {
  font-size: 13px;
  color: #209965;
}

.btn-play {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes pulse {
  0% {
    background-color: #4CAF50;
  }
  50% {
    background-color: #45a049;
  }
  100% {
    background-color: #f15a13;
  }
}

@keyframes place-holder-animation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.game_placeholder {
  display: inline-block;
  /* height: 20px;
  width: 100%; */
  background: rgba(0, 0, 0, 0.07);
  border-radius: 50rem;
  animation: place-holder-animation 2s infinite;
}

.game-play-now:hover{
    background-color: #45a049;
}

.game-play-now {
  width: 100%;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  animation: pulse 1s infinite;
}

.responsive-iframe {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.responsive-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.max-1-line {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

.max-2-line {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

.ant-card-body:empty {
  display: none;
}

@media only screen and (max-width: 768px) {
  .m-grid-12 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .m-grid-11 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .grid-10 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .grid-9 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .grid-8 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .m-grid-7 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .m-grid-6 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .m-grid-5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .m-grid-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .m-grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .m-grid-2 {
    grid-template-columns: 1fr 1fr;
  }

  .m-grid-1 {
    grid-template-columns: 1fr;
  }

  .responsive-iframe-mobile {
    /* mobile ratio 10:16 */
    padding-bottom: 160%;
    
  }
}